import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import "./Home.css";
import TabNavigation from "./TabNavigation";
import CalltoActionCard from "./cta";

import Testimonial from "./Testimonial";
import BuiltForEveryone from "./BuiltForEveryone";

const Home = () => {
  const [showBanner, setShowBanner] = useState(
    !document.cookie.includes("cookie_consent"),
  );

  const [hideImmediately, setHideImmediately] = useState(false);

  const handleAccept = () => {
    document.cookie =
      "cookie_consent=accepted; path=/; max-age=" + 60 * 60 * 24 * 30;
    setHideImmediately(true);
    setShowBanner(false);
  };

  return (
    <>
      {/* Cookie */}
      <AnimatePresence>
        {showBanner && (
          <motion.div
            className="fixed bottom-0 left-0 right-0 p-4 z-50 flex justify-center"
            initial={{ y: "100%" }} // Start below the view
            animate={{ y: 0 }} // Slide up to the view
            exit={{ y: "100%" }} // Slide down out of view
            transition={{
              delay: hideImmediately ? 0 : 0.6, // No delay if hideImmediately is true
              duration: hideImmediately ? 0.2 : 1, // Shorter duration if hideImmediately is true
              ease: "easeInOut", // Smoothing function for the transition
            }}
          >
            <div className="w-full max-w-xl p-4 bg-cookie text-white rounded-xl">
              {/* Banner Content */}
              <div className="flex flex-row">
                <div className="flex md:text-left">
                  <p className="text-sm md:text-xl">
                    We use cookies to make our site user friendly.{" "}
                    <br className="hidden md:block"></br>Read our{" "}
                    <a href="#" className="text-accept">
                      Cookie Policy
                    </a>
                    .
                  </p>
                </div>
                <div className="flex mt-2 md:mt-0 md:ml-4 ml-2">
                  <button
                    onClick={handleAccept}
                    className="bg-accept text-white md:px-4 md:py-2 px-3 py-2  rounded font-medium text-sm tracking-widest"
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {/* Cookie */}

      {/* <Header /> */}
      <Header />
      <AnimatePresence>
        <motion.div
          className="flex flex-col items-center justify-center p-3 md:p-5 bg-white md:h-[75vh]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="d-flex flex-col md:flex-row justify-center"
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <motion.div
              className="md:mt-28 md:ml-12 ml-5"
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h1 className="text-5xl font-medium py-1 text-black leading-[3.5rem] md:leading-none">
                Unlock professional intros<br className="hidden md:block"></br>
                <span className="inline md:hidden">&nbsp;</span>with ease
              </h1>

              <p className="py-2 text-black text-xl md:ml-0 mr-4 mb-3 md:mb-4">
                Professional auto matching platform to build more network.
              </p>
              <Link
                className="normal-case h-full font-bold text-sm md:ml-0 px-4 py-2 rounded-md bg-skincolor text-black hover:bg-skincolor"
                to="/main/signup"
              >
                Free Signup
              </Link>
            </motion.div>

            <motion.div
              className="flex justify-center md:ml-20 -ml-4 mt-4 md:-mt-2 w-[110%] md:w-[35%]"
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <img
                className="hero-image"
                style={{ width: "90%" }}
                src="\home.png"
                alt=""
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </AnimatePresence>

      {/* TabNavigation Component */}
      <TabNavigation />

      {/*How it works section*/}
      {/*
      <div
        className="flex flex-col items-center justify-center p-3 md:-mb-10"
        style={{ backgroundColor: "white" }}
      >
        <div className="text-center">
          <p className="py-2 text-black font-semibold text-4xl">How it works</p>
          <p className="py-2 text-black text-xl">
            Kreedify allows founders to connect with kreedify pros,{" "}
            <br className="hidden sm:block" />
            who have great access to investors in their network.
            <br className="hidden sm:block" />
            Get 1:1 intros with great investors through their network!
          </p>
        </div>
        <div className="flex justify-center md:-mt-2 w-[110%] md:w-[35%]">
          <img src="/kreedifypro.png" alt="" className=" h-48 sm:h-auto" />
        </div>
      </div>
      */}

      {/*Raise funding more effectively*/}
      {/*
      <div
        className="flex flex-col md:flex-row items-center justify-center p-3 md:mt-5 -ml-12 md:-ml-36"
        style={{ backgroundColor: "white" }}
      >
        <div className="col-md-3 ml-16 md:ml-0 mb-3">
          <img
            src="/Kreedify_pro_list_mobileMockup.png"
            alt="kreedify pro list mockup"
            className="h-[400px] md:h-[30rem]"
          />
        </div>
        <div className="ml-12 md:-ml-20 md:mt-0 text-center md:!text-left">
          <p className="py-2 text-black font-semibold text-4xl ml-4 md:ml-0">
            Raise funding more effectively
          </p>

          <p className="py-2 -ml-12 text-black text-xl ml-4 md:ml-0">
            ‘Raising capital can be so difficult without the network.
            <br className="hidden sm:block"></br>
            Not anymore!<br className="hidden sm:block"></br>
            We make it easier for founders to grow their network &{" "}
            <br className="hidden sm:block"></br>
            make fundraising more easy going’
            <br className="hidden sm:block"></br>
          </p>
        </div>
      </div>
      */}

      {/*Help startup's raise funding*/}
      {/*
      <div className="flex flex-col md:flex-row items-center justify-center p-3 md:mt-5 -ml-12 md:ml-0">
        <div className="col-md-3 ml-16 md:ml-0 mb-3 md:hidden">
          <img
            src="/startuplist_mobileMockup.png"
            alt="kreedify pro list mockup"
            className="h-[400px] md:h-[30rem]"
          />
        </div>
        <div className="ml-12 md:ml-0 md:mt-0 text-center md:!text-left">
          <h2 className="font-semibold text-black text-4xl py-3">
            Help startup's raise funding
          </h2>
          <p className="py-2 text-black text-xl">
            ‘As working professionals, you may have access to{" "}
            <br className="hidden sm:block"></br>
            investors in your network- Angle, VC or any type.{" "}
            <br className="hidden sm:block"></br>
            Or, you are a VC scout hunting for startups!{" "}
            <br className="hidden sm:block"></br>
            Help founders to access that network you have &{" "}
            <br className="hidden sm:block"></br>
            be their funding assist’<br></br>
          </p>
          <Link
            className="btn normal-case font-bold mt-2 md:mt-8 text-sm"
            style={{ backgroundColor: "#b0ff4b", color: "black" }}
            to="/main/scout"
            onClick={scrollToTop}
          >
            Register as Scout
          </Link>
        </div>
        <div className="col-md-3 ml-36 mb-3 hidden md:block">
          <img
            src="/startuplist_mobileMockup.png"
            alt="kreedify pro list mockup"
            className="h-[20rem] md:h-[30rem] w-[13rem] md:w-[65%]"
          />
        </div>
      </div>
      */}

      {/*Leverage your network*/}
      {/*
      <div className="flex flex-col md:flex-row items-center justify-center p-3 md:mt-5 -ml-12 md:-ml-20">
        <div className="col-md-3 ml-16 md:ml-0 mb-3">
          <img
            src="/chatting_mobileMockup.png"
            alt="chatting mockup"
            className="h-[400px] md:h-[30rem]"
          />
        </div>
        <div className="ml-12 md:-ml-10 md:mt-0 text-center md:!text-left">
          <h2 className="font-semibold text-black text-4xl py-3">
            Leverage your network
          </h2>
          <p className="py-2 text-black text-xl">
            ‘Our aim is to amplify using network effects.{" "}
            <br className="hidden sm:block"></br>
            Anyone who has access to investors can join us as a scout to{" "}
            <br className="hidden sm:block"></br>
            help founders! We simply increase the chances for startups to{" "}
            <br className="hidden sm:block"></br>
            raise funds’
          </p>
          <Link
            className="btn normal-case font-bold mt-2 md:mt-8 text-sm"
            style={{ backgroundColor: "#b0ff4b", color: "black" }}
            to="/main/signup"
            onClick={scrollToTop}
          >
            Free Signup
          </Link>
        </div>
      </div>
      */}

      <BuiltForEveryone />

      <div className="row justify-content-center mx-auto w-full h-fit mt-6">
        <div
          className="col-md-3 p-4 mx-3 my-4 justify-content-center align-items-center text-black border-t-4 border-t-skincolor rounded-lg shadow-md"
          style={{ backgroundColor: "white", width: "300px" }}
        >
          <div className="row ">
            <div className="col-md-3 mb-2">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3133/3133430.png"
                alt=""
                style={{ width: "50px" }}
              />
            </div>
            <div className="col-md-9">
              <p>More easier and better connections</p>
            </div>
          </div>
        </div>
        <div
          className="col-md-3 p-4  mx-3 my-4 justify-content-center align-items-center bg-white text-black border-t-4 border-t-skincolor rounded-lg shadow-md"
          style={{ width: "300px" }}
        >
          <div className="row">
            <div className="col-md-3 mb-2">
              <img
                src="https://cdn-icons-png.flaticon.com/512/9576/9576835.png"
                alt=""
                style={{ width: "50px" }}
              />
            </div>
            <div className="col-md-9">
              <p>find your cofounder in any background</p>
            </div>
          </div>
        </div>
        <div
          className="col-md-3  p-4  mx-3 my-4  justify-content-center align-items-center text-black border-t-4 border-t-skincolor rounded-lg shadow-md"
          style={{ backgroundColor: "white", width: "300px" }}
        >
          <div className="row">
            <div className="col-md-3 mb-2">
              <img
                src="https://cdn-icons-png.flaticon.com/512/7656/7656523.png"
                alt=""
                style={{ width: "50px" }}
              />
            </div>
            <div className="col-md-9">
              <p>In-built chat when you connect with profiles</p>
            </div>
          </div>
        </div>
        <div
          className="col-md-3 p-4  mx-3 my-4 bg-white justify-content-center align-items-center text-black border-t-4 border-t-skincolor rounded-lg shadow-md"
          style={{ width: "300px" }}
        >
          <div className="row">
            <div className="col-md-3 mb-2">
              <img
                src="https://cdn-icons-png.flaticon.com/512/1651/1651707.png"
                alt=""
                style={{ width: "50px" }}
              />
            </div>
            <div className="col-md-9">
              <p>Auto call scheduling (coming soon)</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Testimonial />
      </div>
      <div className="mt-6 mb-6">
        <CalltoActionCard />
      </div>
    </>
  );
};

export default Home;

//startup list details add
//profile page
//detail id se
