import {
  FaCode,
  FaUserFriends,
  FaBriefcase,
  FaGraduationCap,
  FaHospital,
  FaRocket,
  FaChartBar,
  FaHandshake,
  FaPalette,
} from "react-icons/fa";
import { motion } from "framer-motion";

const BuiltForEveryone = () => {
  const occupations = [
    { title: "Developer", icon: FaCode },
    { title: "Student", icon: FaGraduationCap },
    { title: "Founder", icon: FaRocket },
    { title: "HR Manager", icon: FaUserFriends },
    { title: "Entrepreneur", icon: FaBriefcase },
    { title: "Product Manager", icon: FaHospital },
    { title: "Business Analyst", icon: FaChartBar },
    { title: "Investor", icon: FaHandshake },
    { title: "Professional", icon: FaBriefcase },
    { title: "Designer", icon: FaPalette },
  ];

  return (
    <section className="relative w-full overflow-hidden bg-white py-20 mt-24 mb-16 md:mt-24 md:mb-24 ">
      <div className="container mx-auto px-4">
        <div className="relative flex flex-col items-center">
          {/* Top occupations */}
          <div className="mb-6 md:mb-8 flex flex-wrap justify-center gap-4">
            {occupations.slice(0, 5).map((occupation, index) => (
              <OccupationBubble
                key={occupation.title}
                occupation={occupation}
                index={index}
                top
              />
            ))}
          </div>

          {/* Main heading */}
          <motion.h1
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="text-center text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl mb-6 md:mb-8 "
          >
            BUILT FOR EVERYONE
          </motion.h1>

          {/* Bottom occupations */}
          <div className="flex flex-wrap justify-center gap-4">
            {occupations.slice(5).map((occupation, index) => (
              <OccupationBubble
                key={occupation.title}
                occupation={occupation}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

function OccupationBubble({ occupation, index, top = false }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: top ? 20 : -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.5,
        delay: 0.1 + index * 0.1,
        ease: "easeOut",
      }}
    >
      <div className="flex items-center gap-2 rounded-full bg-primary/10 px-4 py-2 shadow-lg backdrop-blur-sm hover:bg-primary/20 transition-colors">
        <occupation.icon className="h-7 w-7 text-skincolor" />
        <span className="text-xs md:text-base font-medium text-primary-foreground">
          {occupation.title}
        </span>
      </div>
    </motion.div>
  );
}

export default BuiltForEveryone;
