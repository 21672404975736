import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import app_config from "../../config";
import { fetchStarupStageLabel } from "../../Constants";
import AlphabetAvatar from "../AlphabetAvatar";
import { findCountryLabel } from "../commons/countries";
import { checkProfileImageExist } from "../../util.js";
import MyNetwork from "./MyNetwork";
import Register from "./Register.js";
import { FaCheckCircle } from "react-icons/fa";
import { Modal, Box, Typography } from "@mui/material";

export default function MyProfile() {
  const { id } = useParams();
  const location = useLocation();

  // console.log(id);

  const [startupData, setStartupData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");
  const [networkCount, setNetworkCount] = useState(0);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [invitationRequests, setInvitationRequests] = useState([]);
  const [showFullBrief, setShowFullBrief] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const token = sessionStorage.getItem("token");

  const fetchProfileById = async () => {
    setLoading(true);
    const res = await fetch(`${app_config.apiurl}/users/getbyid/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    if (data.error === "Invalid Token") {
      sessionStorage.clear();
      window.location.href = "/";
    }
    console.log(data);
    setStartupData(data.result);
    setLoading(false);
  };

  const fetchApprovedRequests = async () => {
    try {
      const response = await fetch(`${app_config.apiurl}/users/connections`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const approvedRequestsResult = data.result.filter(
        (request) => request.sender !== null,
      );
      setApprovedRequests(approvedRequestsResult);
      setNetworkCount(approvedRequestsResult.length);
    } catch (error) {
      console.error("Error fetching approved requests:", error);
    }
  };

  const fetchPendingRequests = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await fetch(
        `${app_config.apiurl}/users/connections?status=pending`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const data = await response.json();
      const pendingRequestsResult = data.result.filter(
        (request) => request.sender !== null,
      );
      setPendingRequests(pendingRequestsResult); // Store pending requests
    } catch (error) {
      console.error("Error fetching pending requests:", error);
    }
  };

  const fetchInvitationRequests = async () => {
    const token = sessionStorage.getItem("token");
    const res = await fetch(
      `${app_config.apiurl}/connection-requests/recieved`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await res.json();
    const invitationRequestsResult = data.result.filter(
      (request) => request.sender !== null && request.status === "pending",
    );
    console.log("invitationRequestsResult", invitationRequestsResult);
    setInvitationRequests(invitationRequestsResult);
  };

  const handleConnectionRequest = async (requestId, approve) => {
    const res = await fetch(
      `${app_config.apiurl}/connection-requests/${requestId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ approve }),
      },
    );
    if (res.ok) {
      fetchInvitationRequests();
      fetchApprovedRequests();
    }
  };
  const createVerificationRequest = async () => {
    const res = await fetch(`${app_config.apiurl}/users/request-verification`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.ok) {
      fetchProfileById();
    }
  };

  const handleVerificationRequest = () => {
    setShowVerificationModal(true);
  };

  const confirmVerificationRequest = async () => {
    setShowVerificationModal(false);
    await createVerificationRequest();
  };

  const cancelVerificationRequest = () => {
    setShowVerificationModal(false);
  };

  const queryParams = new URLSearchParams(location.search);
  const subTab = queryParams.get("subTab");

  useEffect(() => {
    const tab = queryParams.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
    fetchProfileById();
    fetchApprovedRequests();
    fetchPendingRequests();
    fetchInvitationRequests();
  }, [location.search]);

  const updateProfileData = () => {
    fetchProfileById();
  };

  const displayDetails = () => (
    <div>
      <div className=" h-[15rem] md:h-[10rem]  flex flex-col ">
        <div className="border-b text-[15px] md:text-base border-black pb-2 ml-4 md:ml-12 mr-4 md:mr-[25%]   flex justify-center md:justify-start items-center space-x-3 md:space-x-4 mt-10 md:mt-12 ">
          <button
            className={`px-4 py-2 font-medium ${activeTab === "profile" ? "bg-black text-white rounded-lg" : "bg-transparent text-black"}`}
            onClick={() => setActiveTab("profile")}
          >
            Profile
          </button>
          <button
            className={`flex justify-center items-center gap-x-1.5 px-3 md:px-3  py-2 font-medium ${activeTab === "myNetwork" ? "bg-black text-white rounded-lg" : "bg-transparent text-black"}`}
            onClick={() => setActiveTab("myNetwork")}
          >
            <span> My Network</span>

            <span className=" flex items-center justify-center px-1 py-1 mx-0.5 text-xs font-bold leading-none text-black bg-white rounded-full">
              {networkCount}
            </span>
          </button>
          <button
            className={`relative px-6 py-2 font-medium ${activeTab === "Register" ? "bg-black text-white rounded-lg" : "bg-transparent text-black"}`}
            onClick={() => setActiveTab("Register")}
          >
            Edit profile
          </button>
        </div>
      </div>
      <div className="sm:px-6  mt-[-7rem] md:-mt-8 max-w-6xl flex flex-col sm:flex-row">
        {activeTab === "profile" && (
          <>
            <div className="col-md-4 mb-4">
              <div className="flex flex-col gap-2 items-center py-6 sm:p-6 bg-[#fff] mx-4 rounded-xl shadow-xl relative">
                {/* Notification message with icon */}
                <div className="absolute top-2 left-2 mr-2 flex items-center gap-1 px-3 py-1 bg-[#f3f4f6] text-black text-xs rounded-full shadow-md">
                  <i className="fas fa-eye"></i>
                  <span>Your profile is visible to others</span>
                </div>

                <div className="mt-4">
                  {checkProfileImageExist(startupData.startupimage) ? (
                    <img
                      className="w-32 h-32 rounded-full"
                      src={startupData.startupimage}
                      alt="avatar"
                    />
                  ) : (
                    <AlphabetAvatar
                      alphabet={startupData.firstName.charAt(0)}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2 justify-center  items-center">
                  <div className="flex gap-x-2 justify-center  items-center ">
                    <span
                      className={`text-2xl  ${startupData.firstName.length >= 19 ? "max-w-[70%]" : "max-w-full"}  font-medium text-center capitalize`}
                    >
                      {startupData.firstName}
                    </span>

                    {startupData.verificationStatus === "verified" && (
                      <FaCheckCircle
                        className={`${startupData.firstName.length >= 19 ? "-ml-2" : ""} text-green-600`}
                        size={`${startupData.firstName.length >= 19 ? "1.3rem" : "1.2rem"}`}
                      />
                    )}
                  </div>
                  <p className="text-center px-4">
                    <i className="fas fa-quote-left"></i>{" "}
                    {startupData.brief.split(" ").length > 25 && showFullBrief
                      ? startupData.brief
                      : startupData.brief.split(" ").length < 25
                        ? startupData.brief
                        : `${startupData.brief.split(" ").slice(0, 25).join(" ")}...`}
                  </p>
                  {startupData.brief.split(" ").length > 25 && (
                    <button
                      onClick={() => setShowFullBrief(!showFullBrief)}
                      className="text-blue-500 underline mt-2"
                    >
                      {showFullBrief ? "Read Less" : "Read More"}
                    </button>
                  )}
                  <button
                    className="flex items-center gap-2 px-4 py-2 mt-2 bg-skincolor text-black font-medium rounded-lg"
                    onClick={() => setActiveTab("Register")}
                  >
                    <i className="fas fa-edit"></i> Edit
                  </button>
                  {startupData.verificationStatus !== "verified" && (
                    <>
                      {startupData.verificationStatus === "pending" ? (
                        <div className="text-center text-xs my-3">
                          Verification is under process
                        </div>
                      ) : (
                        <>
                          <button
                            className="flex items-center gap-2 px-4 py-2 mt-2  bg-skincolor text-black text-sm font-medium rounded-lg"
                            onClick={handleVerificationRequest}
                          >
                            <i className="fas fa-check-circle"></i> Request
                            Verification
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-8 px-6 bg-[#fff] shadow-xl rounded-xl mb-4 mx-4 h-fit">
              {/* Basic information */}
              <div>
                <div className="py-2 border-b border-[#d3cdcd]">
                  <h2 className="font-medium text-[19px]">
                    Startup Information
                  </h2>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col sm:flex-row">
                    <div className="flex-1 bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">Startup Type</p>
                        <p className="font-medium">
                          {startupData.type || "Not Available"}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">Startup name</p>
                        <p className="font-medium">
                          {startupData.startupName || "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row">
                    <div className="flex-1 bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">Startup stages</p>
                        <p className="font-medium">
                          {fetchStarupStageLabel(startupData.startupStages)}
                        </p>
                      </div>
                    </div>

                    <div className="flex-1 bg-[#fff] rounded-xl p-3">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#a3a0a0]">Amount Raising</p>
                        <p className="font-medium">
                          {startupData.currentRaiseAmount || "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="py-2 border-b border-[#d3cdcd]">
                  <h2 className="font-medium text-[19px]">Intro AI</h2>
                </div>
                <div className="flex flex-col ">
                  <div className="flex flex-col   ">
                    <div className="flex flex-col md:flex-row ">
                      <div className="flex-1 bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Support needed:</p>
                          <p className="font-medium">
                            {startupData.support_needed.join(", ") ||
                              "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="flex-1 bg-[#fff] rounded-xl  p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Strongest Skills:</p>
                          <p className="font-medium">
                            {startupData.strongest_skills.join(", ") ||
                              "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row  justify-around">
                      <div className="flex-1 bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Years of experience:</p>
                          <p className="font-medium">
                            {startupData.years_of_experience || "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="flex-1 bg-[#fff]  rounded-xl px-3 mt-3">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Intrested In:</p>
                          <p className="font-medium">
                            {startupData.interested_in.join(", ") ||
                              "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`bg-[#fff]  p-3  w-full mt-3 md:!mt-1`}>
                    <div className=" flex flex-col gap-2 ">
                      <p className="text-[#a3a0a0]">Intro Message</p>
                      <p className="font-medium">
                        {startupData.intro_message || "Not Available"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="py-2 border-b border-[#d3cdcd]">
                  <h2 className="font-medium text-[19px]">Basic Information</h2>
                </div>
                <div className="flex flex-col sm:grid sm:grid-cols-2 sm:gap-4  ml-2 mb-4">
                  <div className="bg-[#fff] rounded-xl p-2">
                    <div className="flex flex-col gap-2">
                      <p className="text-[#a3a0a0]">Email</p>
                      <p
                        className="font-medium break-words"
                        style={{ wordBreak: "break-word" }}
                      >
                        {startupData.email}
                      </p>
                    </div>
                  </div>
                  <div className="bg-[#fff]  rounded-xl p-2 mt-3 md:!mt-1">
                    <div className="flex flex-col gap-2">
                      <p className="text-[#a3a0a0]">LinkedIn URL</p>
                      <a
                        href={startupData.linkedInUrl || "#"}
                        target="_blank"
                        className="font-medium overflow-hidden whitespace-nowrap"
                        style={{
                          maxWidth: "300px",
                          textOverflow: "ellipsis",
                          display: "block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {startupData.linkedInUrl}
                      </a>
                    </div>
                  </div>
                  <div className="bg-[#fff] rounded-xl p-2 mt-3 md:!mt-1">
                    <div className="flex flex-col gap-2">
                      <p className="text-[#a3a0a0]">Phone Number</p>
                      <p className="font-medium">
                        {startupData.tel || "Not Available"}
                      </p>
                    </div>
                  </div>
                  <div className="bg-[#fff]  rounded-xl p-2 mt-3 md:!mt-1">
                    <div className="flex flex-col gap-2">
                      <p className="text-[#a3a0a0]">Website</p>
                      {startupData.website ? (
                        <a
                          href={startupData.website}
                          target="_blank"
                          className="font-medium overflow-hidden whitespace-nowrap"
                          style={{
                            maxWidth: "300px",
                            textOverflow: "ellipsis",
                            display: "block",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {startupData.website}
                        </a>
                      ) : (
                        <p className="text-black font-medium">Not Available</p>
                      )}
                    </div>
                  </div>
                  <div className="bg-[#fff] rounded-xl p-2 mt-3 md:!mt-1">
                    <div className="flex flex-col gap-2">
                      <p className="text-[#a3a0a0]">Location</p>
                      <p className="font-medium">
                        {findCountryLabel(startupData.location) ||
                          "Not Available"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {activeTab === "myNetwork" && (
          <MyNetwork
            approvedRequests={approvedRequests}
            pendingRequests={pendingRequests}
            invitations={invitationRequests}
            handleConnectionRequest={handleConnectionRequest}
          />
        )}
        {activeTab === "Register" && (
          <Register onUpdate={updateProfileData} activeSubTab={subTab} />
        )}
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <div className="text-center mt-64">
          <CircularProgress color="success" />
        </div>
      ) : (
        startupData && displayDetails()
      )}
      <Modal
        open={showVerificationModal}
        onClose={cancelVerificationRequest}
        aria-labelledby="verification-modal-title"
        aria-describedby="verification-modal-description"
      >
        <Box
          className="modal-content"
          sx={{
            p: 4,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            width: { xs: "90%", sm: "70%", md: "50%" },
            maxWidth: "600px",
            mx: "auto",
            my: "30vh",
          }}
        >
          <Typography id="verification-modal-title" variant="h6" component="h2">
            Get more visibility with a green tick mark verification.
          </Typography>
          <Typography id="verification-modal-description" sx={{ mt: 2 }}>
            Your profile will be verified if you have a genuine profile picture,
            profile details & general conduct on the platform.
          </Typography>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "end", gap: 4 }}>
            <button
              onClick={cancelVerificationRequest}
              className="bg-transparent border-2 text-black font-medium rounded-lg  px-7 py-2"
              Adjusted
              width
            >
              No
            </button>
            <button
              onClick={confirmVerificationRequest}
              className="bg-skincolor text-black font-medium rounded-lg  px-7 py-2"
            >
              Yes
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
