import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";

const InvestorAuth = ({ children }) => {
  const currentUser = JSON.parse(sessionStorage.getItem("investor"));

  if (currentUser !== null) {
    return children;
  } else {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Please Login First!!",
    });
    return <Navigate to="/main/login" />;
  }
};

export default InvestorAuth;
