import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import app_config from "../../config";
import ChatForm from "./ChatForm";
import ChatListItemCard from "./ChatListItemCard";
import Message from "./Message";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // Three dots icon
import DeleteIcon from "@mui/icons-material/Delete"; // Add this import

import Swal from "sweetalert2";
import { useSocket } from "../../context/SocketProvider";

export default function ChatRoom({ user, onBack, updateContactsOnMessage }) {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false); // State to manage menu visibility
  const currentUser =
    JSON.parse(sessionStorage.getItem("investor")) ||
    JSON.parse(sessionStorage.getItem("startup"));
  const socket = useSocket();
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const fetchChats = async () => {
      const res = await fetch(
        `${app_config.apiurl}/chat/${user._id}/messages`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const chatsData = await res.json();
      console.log(chatsData);
      setMessages(chatsData.result);
    };

    fetchChats();
  }, [currentUser._id, user._id]);

  useEffect(() => {
    if (!socket) return;
    socket.emit("addtocontact", currentUser._id);
    socket.on("recmsg", (data) => {
      setMessages([...messages, data]);
      updateContactsOnMessage(data);
    });
    return () => socket.off("recmsg");
  }, [socket, updateContactsOnMessage]);

  const handleFormSubmit = async (message) => {
    if (!message.trim()) return;

    const formData = {
      users: [currentUser._id, user._id],
      sentBy: currentUser._id,
      date: new Date(),
      to: user._id,
      message: message,
      name: currentUser.name,
    };

    socket.emit("sendmsg", formData);
    setMessages((prevMessages) => [...prevMessages, formData]);
    updateContactsOnMessage(formData);

    await fetch(`${app_config.apiurl}/chat/${user._id}/messages`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const handleCardClick = () => {
    if (currentUser.role === "startup") {
      if (user.role === "investor") {
        navigate(`/startup/investordetails/${user._id}`);
      } else {
        navigate(`/startup/introai/${user._id}`);
      }
    } else {
      navigate(`/investor/startupdetails/${user._id}`);
    }
  };

  const handleDeleteChat = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This will delete the chat history",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    });

    if (result.isConfirmed) {
      try {
        const response = await fetch(
          `${app_config.apiurl}/chat/${user._id}/delete`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.ok) {
          // Show confirmation alert
          setMessages([]);
          Swal.fire("Deleted!", "Chat has been deleted.", "success");
          setMenuOpen(false);
        } else {
          // Handle unsuccessful response
          Swal.fire(
            "Error!",
            "Failed to delete chat. Please try again.",
            "error",
          );
          console.error("Error deleting chat:", await response.text());
        }
      } catch (error) {
        // Handle errors
        Swal.fire(
          "Error!",
          "An unexpected error occurred. Please try again.",
          "error",
        );
        console.error("Error deleting chat:", error);
      }
    }
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev); // Toggle menu visibility
  };

  return (
    <div className="lg:col-span-2 lg:block h-[79vh] flex flex-col md:h-[72vh] ">
      <div className="w-full h-full flex flex-col">
        {/* Header Section */}
        <div
          className="p-3 bg-white border-b border-gray-200 flex items-center"
          style={{ borderColor: "rgb(229 231 235)" }}
        >
          <div className="flex items-center w-full">
            <button className="block md:hidden mr-4" onClick={onBack}>
              <ArrowBackIosIcon />
            </button>
            <div onClick={handleCardClick} className="cursor-pointer flex-grow">
              <ChatListItemCard user={user} room={true} />
            </div>
            {/* Three dots menu */}
            <div className="relative">
              <button
                className="text-gray-500 relative -top-1"
                onClick={toggleMenu}
              >
                <MoreVertIcon />
              </button>
              {menuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                  <button
                    onClick={handleDeleteChat}
                    className="flex items-center w-full px-4 py-2 text-left text-sm text-red-500 hover:bg-gray-100"
                  >
                    {/* Delete Icon */}
                    <DeleteIcon className="mr-2 w-4 h-4" />
                    Delete All Chat
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Chat Messages */}
        <div
          className="flex-1 w-full max-h-[64vh] md:max-h-[23rem] xl:max-h-[30rem] p-6 overflow-y-auto overflow-x-hidden scrollbar-custom bg-chatbackground border-b border-gray-200 flex flex-col-reverse"
          style={{ borderColor: "rgb(229 231 235)" }}
        >
          <ul className="space-y-2">
            {messages.map((message, index) => (
              <div key={index}>
                <Message message={message} self={currentUser._id} />
              </div>
            ))}
          </ul>
        </div>

        {/* Chat Input Form */}
        <div className="w-full">
          <ChatForm handleFormSubmit={handleFormSubmit} />
        </div>
      </div>
    </div>
  );
}
