import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import app_config from "../config";

const StartupContext = createContext();

const StartupProvider = ({ children, currentStartup }) => {
  const [sLoggedIn, setSloggedIn] = useState(currentStartup !== null);
  const navigate = useNavigate();

  const logout = async () => {
    try {
      const token = sessionStorage.getItem("token");
      await fetch(`${app_config.apiurl}/users/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      sessionStorage.removeItem("startup");
      sessionStorage.removeItem("token");
      setSloggedIn(false);
      navigate("/main/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <StartupContext.Provider value={{ sLoggedIn, setSloggedIn, logout }}>
      {children}
    </StartupContext.Provider>
  );
};

export const useStartupContext = () => useContext(StartupContext);

export default StartupProvider;
