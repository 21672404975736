import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import app_config from "../../config";
import {
  fetchStarupStageLabel,
  startupIndustries,
  startupIndustriesLookup,
  startupStages,
} from "../../Constants";
import { countries, findCountryLabel } from "../commons/countries";
import MultiSelectDropdown from "../commons/MultiSelectDropDown";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import FilterTabs from "../commons/filterTabs";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const StartupList = () => {
  const [filteredStartupList, setFilteredStartupList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [startupStageFilterVal, setStartupStageFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("startupStageFilterVal")) || [],
  );
  const [revenueStageFilterVal, setRevenueStageFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("revenueStageFilterVal")) || [],
  );
  const [industryFilterVal, setIndustryFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("industryFilterVal")) || [],
  );
  const [locationFilterVal, setLocationFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("locationFilterVal")) || [],
  );
  const [raisedAmountFilterVal, setRaisedAmountFilterVal] = useState(
    JSON.parse(sessionStorage.getItem("raisedAmountFilterVal")) || [],
  );

  const [openPC, setOpenPC] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const handleClickOpenpc = (e) => {
    e.preventDefault();
    setOpenPC(true);
  };

  const handleClosepc = () => {
    setOpenPC(false);
  };

  const closeAllDropdowns = () => {
    setActiveDropdown(null);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const profilesPerPage = 10;

  const search = async (event) => {
    event.preventDefault();
    fetchData();
    setCurrentPage(1);
    closeAllDropdowns();
    handleClosepc();
  };

  const url = app_config.apiurl;
  const placeholder =
    "https://via.placeholder.com/640x400.png/a59090/000000?Text=640x400";

  const fetchData = async () => {
    const searchUrl = new URL(`${url}/users/allstartups`);
    const params = new URLSearchParams();
    if (searchKeyword) {
      params.append("search", searchKeyword);
    }
    if (startupStageFilterVal.length > 0) {
      params.append("startupStages", startupStageFilterVal.join(","));
    }
    if (revenueStageFilterVal.length > 0) {
      params.append("revenueStages", revenueStageFilterVal.join(","));
    }
    if (industryFilterVal.length > 0) {
      params.append("industry", industryFilterVal.join(","));
    }
    if (locationFilterVal.length > 0) {
      params.append("location", locationFilterVal.join(","));
    }
    if (raisedAmountFilterVal.length > 0) {
      params.append("raiseAmount", raisedAmountFilterVal);
    }
    searchUrl.search = params.toString();
    const res = await fetch(searchUrl);
    const data = await res.json();
    setFilteredStartupList(data.result);
  };

  useEffect(() => {
    fetchData();
    setCurrentPage(1);
  }, []);

  const isAvatarImageAvaiable = (imageUrl) => {
    console.log("imageUrl:" + imageUrl);
    return (
      imageUrl !== undefined &&
      imageUrl !== null &&
      imageUrl !== "undefined" &&
      imageUrl.trim().length > 0
    );
  };

  const updateStartupStageFilterVal = (val) => {
    setStartupStageFilterVal(val);
    sessionStorage.setItem("startupStageFilterVal", JSON.stringify(val));
  };

  const updateRevenueStageFilterVal = (val) => {
    setRevenueStageFilterVal(val);
    sessionStorage.setItem("revenueStageFilterVal", JSON.stringify(val));
  };

  const updateIndustryFilterVal = (val) => {
    setIndustryFilterVal(val);
    sessionStorage.setItem("industryFilterVal", JSON.stringify(val));
  };

  const updateLocationFilterVal = (val) => {
    setLocationFilterVal(val);
    sessionStorage.setItem("locationFilterVal", JSON.stringify(val));
  };

  const updateRaisedAmountFilterVal = (val) => {
    setRaisedAmountFilterVal(val);
    sessionStorage.setItem("raisedAmountFilterVal", JSON.stringify(val));
  };

  const clearFilters = () => {
    setStartupStageFilterVal([]);
    setRevenueStageFilterVal([]);
    setIndustryFilterVal([]);
    setLocationFilterVal([]);
    setRaisedAmountFilterVal([]);
    sessionStorage.removeItem("startupStageFilterVal");
    sessionStorage.removeItem("revenueStageFilterVal");
    sessionStorage.removeItem("industryFilterVal");
    sessionStorage.removeItem("locationFilterVal");
    sessionStorage.removeItem("raisedAmountFilterVal");
    fetchData();
  };

  const displayList = () => {
    const startIndex = (currentPage - 1) * profilesPerPage;
    const endIndex = startIndex + profilesPerPage;
    const currentList = filteredStartupList.slice(startIndex, endIndex);

    return currentList.map((startup) => (
      <div key={startup._id} className="bg-[#fff] py-3 rounded">
        <div className="">
          <div className="flex flex-row items-center">
            <div className="col-md-4 w-[30%] flex flex-col gap-2 items-center justify-center">
              <img
                className="px-1 py-1 w-24 h-24  rounded-full "
                src={
                  isAvatarImageAvaiable(startup.startupimage)
                    ? startup.startupimage
                    : placeholder
                }
                alt=""
              />
              <div className="">
                <Link
                  type="button"
                  className="btn text-black"
                  to={"/investor/startupdetails/" + startup._id}
                  style={{
                    backgroundColor: "#b0ff4b",
                    padding: "8px",
                    color: "black",
                  }}
                >
                  Read more
                </Link>
              </div>
            </div>
            <div className="w-[70%]  px-1  md:ml-16 lg:ml-1">
              <h1 className="md:text-xl capitalize text-lg">
                <strong> {startup.firstName}</strong>
              </h1>
              <p className="text-[#808080] text-sm  mt-1">
                <strong>
                  {findCountryLabel(startup.location) || "Not Available"}
                </strong>
              </p>
              <div className="flex flex-row mt-2">
                <p className="font-bold text-sm ">
                  Industry:{" "}
                  <span className="md:hidden font-normal text-sm">
                    {startupIndustriesLookup[startup.industry] ||
                      "Not Available"}
                  </span>
                </p>
                <p className="hidden md:ml-2 md:block ">
                  {startupIndustriesLookup[startup.industry] || "Not Available"}
                </p>
              </div>
              <div className="flex flex-row mt-1">
                <p className="font-bold text-sm ">
                  Startup Stages:
                  <span className="md:hidden font-normal text-sm">
                    {fetchStarupStageLabel(startup.startupStages) ||
                      "Not Available"}
                  </span>
                </p>
                <p className="hidden md:block md:ml-2 ">
                  {fetchStarupStageLabel(startup.startupStages) ||
                    "Not Available"}
                </p>
              </div>
              <div className="flex flex-row mt-1">
                <p className="font-bold text-sm ">
                  Current Raised Amount:{" "}
                  <span className="md:hidden font-normal text-sm">
                    {startup.currentRaiseAmount || "Not Available"}
                  </span>
                </p>
                <p className="hidden md:block md:ml-2 ">
                  {startup.currentRaiseAmount || "Not Available"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(filteredStartupList.length / profilesPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(startPage + maxVisiblePages - 1, pageNumbers.length);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    return (
      <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          className={`px-2  py-1  mx-1 border text-sm rounded bg-white text-black ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {startPage > 1 && (
          <>
            <button
              onClick={() => setCurrentPage(1)}
              className={`px-2  py-1  mx-1 border rounded ${
                currentPage === 1
                  ? "bg-black text-white"
                  : "bg-white text-black"
              }`}
            >
              1
            </button>
            {startPage > 2 && (
              <button
                className="px-2  py-1  mx-1 border rounded bg-white text-black"
                disabled
              >
                ...
              </button>
            )}
          </>
        )}
        {pageNumbers.slice(startPage - 1, endPage).map((number) => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            className={`px-2  py-1  mx-1 border rounded ${
              currentPage === number
                ? "bg-black text-white"
                : "bg-white text-black"
            }`}
          >
            {number}
          </button>
        ))}
        {endPage < pageNumbers.length && (
          <>
            {endPage < pageNumbers.length - 1 && (
              <button
                className="px-2  py-1  mx-1 border rounded bg-white text-black"
                disabled
              >
                ...
              </button>
            )}
            <button
              onClick={() => setCurrentPage(pageNumbers.length)}
              className={`px-2  py-1  mx-1 border text-lg rounded ${
                currentPage === pageNumbers.length
                  ? "bg-black text-white"
                  : "bg-white text-black"
              }`}
            >
              {pageNumbers.length}
            </button>
          </>
        )}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          className={`px-2  py-1  mx-1 border rounded bg-white text-sm text-black ${
            currentPage === pageNumbers.length
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          disabled={currentPage === pageNumbers.length}
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "-135px",
          display: "flex",
        }}
        className="h-24 sm:h-60 md:justify-start justify-center"
      >
        <h1 className="text-black md:ml-7 pt-6 text-4xl font-semibold mt-3 sm:mt-0">
          Startup List
        </h1>
      </div>
      <form onSubmit={search} className="mt-40 sm:mt-0">
        <div className="px-4 flex flex-col gap-3">
          <Dialog
            fullScreen={fullScreen}
            open={openPC}
            onClose={handleClosepc}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
              sx: {
                width: "100%",
                height: "65vh",
                margin: 0,
              },
            }}
          >
            <div className="flex justify-between px-4 py-2 border-b border-[#000]">
              <div>
                <h2 className="text-2xl">Filters</h2>
              </div>
              <button onClick={handleClosepc} className="btn p-2 bg-[#b0ff4b]">
                <CloseIcon />
              </button>
            </div>
            <DialogContent dividers>
              <div className="flex flex-wrap  gap-1">
                <MultiSelectDropdown
                  options={startupStages.map((stg) => ({
                    label: stg.text,
                    val: stg.value,
                  }))}
                  label="Stage of Startup"
                  onSelectionChange={updateStartupStageFilterVal}
                  isOpen={activeDropdown === "startupStage"}
                  setIsOpen={() => toggleDropdown("startupStage")}
                  selectedOptions={startupStageFilterVal}
                />
                <MultiSelectDropdown
                  options={[
                    { label: "Funded", val: "Funded" },
                    { label: "Bootstrapped", val: "Bootstrapped" },
                  ]}
                  label="Revenue Stage"
                  onSelectionChange={updateRevenueStageFilterVal}
                  isOpen={activeDropdown === "revenueStage"}
                  setIsOpen={() => toggleDropdown("revenueStage")}
                  selectedOptions={revenueStageFilterVal}
                />
                <MultiSelectDropdown
                  options={startupIndustries.map((option) => ({
                    label: option.text,
                    val: option.value,
                  }))}
                  label="Industry"
                  onSelectionChange={updateIndustryFilterVal}
                  isOpen={activeDropdown === "industry"}
                  setIsOpen={() => toggleDropdown("industry")}
                  selectedOptions={industryFilterVal}
                />
                <MultiSelectDropdown
                  options={countries.map((cc) => ({
                    label: cc.name,
                    val: cc.value,
                  }))}
                  label="Location"
                  onSelectionChange={updateLocationFilterVal}
                  isOpen={activeDropdown === "location"}
                  setIsOpen={() => toggleDropdown("location")}
                  selectedOptions={locationFilterVal}
                />
                <input
                  type="number"
                  className="form-control w-[14rem]"
                  placeholder="Current Raised Amount"
                  value={raisedAmountFilterVal}
                  onChange={(e) => updateRaisedAmountFilterVal(e.target.value)}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={search}
                className="ml-4 hidden md:block inline-flex justify-center w-[12rem] md:w-32 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-black text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
              >
                Search
              </button>
              <button
                className="-ml-4 hidden md:block inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-black text-sm font-medium text-white hover:bg-gray-700 focus:outline-none"
                onClick={clearFilters}
              >
                Clear
              </button>
            </DialogActions>
          </Dialog>

          <div className="flex relative w-[18rem] md:w-[25rem] h-[3rem] md:h-[2.5rem]">
            <button
              type="submit"
              className="absolute left-0 top-0 h-full flex items-center justify-center pl-2"
            >
              <SearchIcon className="text-gray-500" />
            </button>
            <input
              className="pl-9 md:pl-10 pr-2 py-1 md:py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full rounded-lg border border-black"
              value={searchKeyword}
              placeholder="Search for startups"
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <button
              onClick={handleClickOpenpc}
              className="hidden  px-2  py-1 mx-1 border rounded bg-white text-black md:flex gap-2 items-center"
            >
              <FilterAltOutlinedIcon />
              Filter
            </button>
          </div>

          {/* filter for mobile */}
          <FilterTabs
            listType="StartUpList"
            labels={[
              "Stage of Startups",
              "Revenue Stage",
              "Industry",
              "Location",
              "Current Raised Amount",
            ]}
            setStartupStageFilteVal={updateStartupStageFilterVal}
            startupStageFilteVal={startupStageFilterVal}
            setRevenueStageFilteVal={updateRevenueStageFilterVal}
            revenueStageFilteVal={revenueStageFilterVal}
            setIndustryFocusFilteVal={updateIndustryFilterVal}
            industryFocusFilteVal={industryFilterVal}
            setLocationFilterVal={setLocationFilterVal}
            locationFilterVal={locationFilterVal}
            setRaisedAmountFilteVal={updateRaisedAmountFilterVal}
            raisedAmountFilteVal={raisedAmountFilterVal}
            search={search}
            clearFilters={() => {
              clearFilters();
            }}
          />
        </div>
      </form>

      <div className="flex justify-start md:ml-6 mb-4 ml-5 ">
        {renderPagination()}
      </div>

      <div className="flex justify-center md:justify-start mb-8">
        <div className="ml-4 w-[90%] md:w-[85%] 2xl:w-[65%]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {displayList()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupList;
