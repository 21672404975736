import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useStartupContext } from "../../context/StartupProvider";
import useMobileView from "../../hooks/use-mobile-view";
import DashboardContainer from "../dashboard-container";
import { startupSideMenus } from "./user-menu";

const Dashboard = () => {
  const isMobileView = useMobileView();
  const { logout } = useStartupContext();
  const currentUser = JSON.parse(sessionStorage.getItem("startup"));
  const sideMenu = isMobileView
    ? startupSideMenus(currentUser, logout)
    : startupSideMenus(currentUser, logout);

  console.log("Rendering StartupDashboard");

  return (
    <div className="module">
      <DashboardContainer menuItems={sideMenu} isMobileView={isMobileView}>
        <Outlet />
      </DashboardContainer>
    </div>
  );
};

export default Dashboard;
