import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import app_config from "../../config";
import {
  industryFocusOptionLookup,
  networkTypeLookup,
  scoutSupportedStartupStagesLookup,
} from "../../Constants";
import AlphabetAvatar from "../AlphabetAvatar";
import { findCountryLabel } from "../commons/countries";

const InvestorDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [investorData, setInvestorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("none");

  const fetchInvestorById = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    const res = await fetch(`${app_config.apiurl}/users/profile-detail/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    console.log(data);
    setConnectionStatus(data.connctionRequestStatus);
    setInvestorData(data.result);
    setLoading(false);
  };

  useEffect(() => {
    fetchInvestorById();
  }, [id]);

  const openChat = (investorData) => {
    navigate("/startup/inbox", { state: { selectedUser: investorData } });
  };

  const sendConnectionRequest = async () => {
    const token = sessionStorage.getItem("token");

    await fetch(`${app_config.apiurl}/connection-requests/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ to: id }),
    });

    // Refresh the investor details after sending the request
    fetchInvestorById();
  };

  const isAvatarImageAvailable = (imageUrl) => {
    console.log("Image url" + imageUrl);
    return (
      imageUrl !== undefined &&
      imageUrl !== null &&
      imageUrl !== "undefined" &&
      imageUrl.trim().length > 0
    );
  };

  const renderButtonSection = () => {
    let buttonLabel = "Connect";
    let buttonAction = sendConnectionRequest;
    let isDisabled = false;

    if (connectionStatus === "approved") {
      buttonLabel = "Start Chatting";
      buttonAction = () => openChat(investorData);
    } else if (connectionStatus === "pending") {
      buttonLabel = "Pending";
      isDisabled = true;
    }

    return (
      <button
        className="p-2 rounded-lg"
        style={{
          backgroundColor: "#b0ff4b",
          color: "black",
          width: "fit-content",
        }}
        onClick={buttonAction}
        disabled={isDisabled}
      >
        {buttonLabel}
      </button>
    );
  };

  const displayDetails = () => {
    if (!loading && investorData) {
      return (
        <div>
          <div className=" h-[12rem] flex md:justify-start justify-center">
            <h1 className="text-black font-bold text-[2.5rem] pt-4 md:ml-20 mt-8">
              Details
            </h1>
          </div>
          <div className="sm:px-12 mt-[-4rem] max-w-6xl flex flex-col sm:flex-row ">
            <div className="sm:w-[37%] mb-4 sm:mb-0">
              <div className="flex flex-col gap-4 items-center py-6 sm:p-6  bg-[#fff] mx-4 rounded-xl shadow-xl ">
                <div className=" ">
                  {isAvatarImageAvailable(investorData?.investoravatar) ? (
                    <img
                      className="w-32 h-32 rounded-full"
                      src={investorData?.investoravatar}
                      alt="avatar"
                    />
                  ) : (
                    <AlphabetAvatar
                      alphabet={investorData?.firstName.charAt(0)}
                    />
                  )}
                </div>
                <div className=" flex flex-col gap-2 items-center ">
                  <h2 className="text-2xl  font-medium">
                    {investorData?.firstName}
                  </h2>
                  {investorData?.brief && (
                    <p className="text-center">
                      <i className="fas fa-quote-left"></i>{" "}
                      {investorData?.brief}
                    </p>
                  )}
                </div>
                <div>{renderButtonSection()}</div>
              </div>
            </div>
            <div className="sm:w-[63%] px-6  bg-[#fff] shadow-xl rounded-xl mb-4  mx-4 ">
              {/* Basic information */}
              <div>
                <div className="py-2 border-b border-[#d3cdcd]">
                  <h2 className="font-medium text-[19px]">Basic Information</h2>
                </div>
                <div className="flex flex-col sm:flex-row">
                  <div className="sm:w-[55%]">
                    <div className="bg-[#fff] rounded-xl p-3">
                      <div className=" flex flex-col gap-2 ">
                        <p className="text-[#a3a0a0]">Current Position:</p>
                        <p className="font-medium">
                          {investorData?.currentPosition || "Not Available"}
                        </p>
                      </div>
                    </div>

                    <div className="bg-[#fff] rounded-xl p-3 ">
                      <div className=" flex flex-col gap-2 ">
                        <p className="text-[#a3a0a0]">
                          {" "}
                          Experience helping startups raise funding:
                        </p>
                        <p className="font-medium">
                          {investorData?.experience || "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sm:w-[45%]">
                    <div className="bg-[#fff] rounded-xl p-3 ">
                      <div className=" flex flex-col gap-2 ">
                        <p className="text-[#a3a0a0]">
                          {" "}
                          Type of investor in your network:
                        </p>
                        <p className="font-medium">
                          {investorData?.typeOfInvestor || "Not Available"}
                        </p>
                      </div>
                    </div>

                    <div className="bg-[#fff] rounded-xl px-3 ">
                      <div className=" flex flex-col gap-2 ">
                        <p className="text-[#a3a0a0]">Your expertise:</p>
                        <p className="font-medium">
                          {investorData?.expertise || "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-4 sm:!pt-0">
                <div className="py-2 border-b border-[#d3cdcd]">
                  <h2 className="font-medium text-[19px]">
                    Additional Information
                  </h2>
                </div>
                <div className="flex flex-col sm:flex-row">
                  <div className="sm:w-[55%]">
                    <div className="bg-[#fff] rounded-xl p-3 ">
                      <div className=" flex flex-col gap-2 ">
                        <p className="text-[#a3a0a0]">Your industry focus:</p>
                        <p className="font-medium">
                          {industryFocusOptionLookup[
                            investorData.industryFocus
                          ] || "Not Available"}
                        </p>
                      </div>
                    </div>

                    <div className="bg-[#fff] rounded-xl p-3 ">
                      <div className=" flex flex-col gap-2 ">
                        <p className="text-[#a3a0a0]">
                          Stage of startup’s you help:
                        </p>
                        <p className="font-medium">
                          {scoutSupportedStartupStagesLookup[
                            investorData?.stage
                          ] || "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sm:w-[45%]">
                    <div className="bg-[#fff] rounded-xl p-3 ">
                      <div className=" flex flex-col gap-2 ">
                        <p className="text-[#a3a0a0]">Type of network:</p>
                        <p className="font-medium">
                          {networkTypeLookup[investorData?.networkType] ||
                            "Not Available"}
                        </p>
                      </div>
                    </div>

                    <div className="bg-[#fff] rounded-xl p-3 ">
                      <div className=" flex flex-col gap-2 ">
                        <p className="text-[#a3a0a0]">Location:</p>
                        <p className="font-medium">
                          {findCountryLabel(investorData?.location) ||
                            "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="bg-[#fff] rounded-xl p-3 ">
                    <div className=" flex flex-col gap-2 ">
                      <p className="text-[#a3a0a0]">More bio:</p>
                      <p className="font-medium">
                        {investorData?.bio || "Not Available"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center mt-64">
          <CircularProgress color="success" />
        </div>
      );
    }
  };

  return displayDetails();
};

export default InvestorDetails;
