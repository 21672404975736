import { useState, useEffect } from "react";

const useMobileView = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 840);

  useEffect(() => {
    const handleResize = () => {
      const isMobileViewUpdated = window.innerWidth < 840;
      if (isMobileViewUpdated !== isMobileView) {
        setIsMobileView(isMobileViewUpdated);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isMobileView]);

  useEffect(() => {
    console.log("Mobile view updated:", isMobileView);
  }, [isMobileView]);

  return isMobileView;
};

export default useMobileView;
