const FilterOptions = ({ options, selectedOptions, onSelectionChange }) => {
  //   const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionClick = (option) => {
    const currentSelectedOptions = selectedOptions || [];
    const newSelectedOptions = currentSelectedOptions?.includes(option)
      ? currentSelectedOptions?.filter((item) => item !== option)
      : [...currentSelectedOptions, option];
    onSelectionChange(newSelectedOptions);
  };

  return (
    <div className="flex flex-col gap-2 h-[19rem] overflow-y-scroll">
      {options?.map((option) => (
        <div key={option.val} className="flex items-center px-4 py-2">
          <input
            type="checkbox"
            checked={selectedOptions?.includes(option.val) || false}
            onChange={() => handleOptionClick(option?.val)}
            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
          />
          <label className="ml-3 block text-sm leading-5 text-gray-700">
            {option?.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default FilterOptions;
