import React from "react";
import AlphabetAvatar from "../AlphabetAvatar";

export default function ChatListItemCard({ user, room = false }) {
  let photoURL =
    user.role === "investor" ? user.investoravatar : user.startupimage;
  let displayName =
    user.role === "investor"
      ? `${user.firstName} ${user.lastName}`
      : user.firstName;

  if (room) {
    return (
      <div
        className={`relative flex items-center mb-2 w-full`}
        style={{ borderColor: "rgb(107 114 128)" }}
      >
        {photoURL ? (
          <img className="w-10 h-10 rounded-full" src={photoURL} alt="" />
        ) : (
          <AlphabetAvatar
            className="!w-10 !h-10"
            alphabet={displayName ? displayName.charAt(0) : "?"}
          />
        )}
        <div className={`flex flex-col flex-grow `}>
          <div className="flex justify-between items-center gap-x-5">
            <span className="block ml-2 max-w-[12rem] md:max-w-sm truncate text-gray-900 font-semibold  capitalize">
              {displayName}s
            </span>
          </div>
        </div>
        {/* <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-gray-400 border-2 border-white rounded-full"></span> */}
      </div>
    );
  }

  return (
    <div
      className={`relative flex items-center  w-full py-3 px-3 h-full ${
        !user.lastMessage?.read && user.lastMessage?.sentBy === user._id
          ? "font-bold text-black bg-skincolor bg-opacity-20"
          : ""
      } `}
      style={{ borderColor: "rgb(107 114 128)" }}
    >
      {photoURL ? (
        <img className="w-10 h-10 rounded-full" src={photoURL} alt="" />
      ) : (
        <AlphabetAvatar
          className="!w-10 !h-10"
          alphabet={displayName ? displayName.charAt(0) : "?"}
        />
      )}
      <div className={`flex flex-col flex-grow `}>
        <div className="flex justify-between items-center gap-x-5">
          <span className="block ml-2 max-w-[9rem] truncate text-gray-900  capitalize">
            {displayName}
          </span>
          {user.lastMessage && (
            <span className="text-xs text-gray-400">
              {formatMessageTime(user.lastMessage.date)}
            </span>
          )}
        </div>

        {user.lastMessage && (
          <div className=" ml-2 flex items-center gap-x-1">
            <div className="flex items-center justify-start  text-xs">
              <div
                className={`flex-grow ${
                  !user.lastMessage.read && user.lastMessage.sentBy === user._id
                    ? "font-bold text-black"
                    : "text-gray-500"
                }`}
              >
                <span>
                  {user.lastMessage.sentBy !== user._id
                    ? "You: "
                    : `${user.firstName}: `}
                </span>
                <span>{truncateMessage(user.lastMessage.message, 15)}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-gray-400 border-2 border-white rounded-full"></span> */}
    </div>
  );
}

const formatMessageTime = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInMs = now - date;
  const diffInHours = diffInMs / (1000 * 60 * 60);

  if (diffInHours < 24) {
    const diffInMinutes = diffInMs / (1000 * 60);
    if (diffInMinutes < 60) {
      if (diffInMinutes < 1) {
        return "Just now";
      }
      return `${Math.floor(diffInMinutes)} minutes ago`;
    }
    return `${Math.floor(diffInHours)} hours ago`;
  }

  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

const truncateMessage = (message, maxLength = 50) => {
  if (message.length > maxLength) {
    return message.substring(0, maxLength) + "...";
  }
  return message;
};
