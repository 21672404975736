import classNames from "classnames";
import React, { useState, useEffect } from "react";
import ChatList from "./ChatList";
import ChatRoom from "./ChatRoom";
import SearchUsers from "./SearchUsers";
import Welcome from "./Welcome";

export default function ChatLayout({ selectedChat, onChatChange, onBack }) {
  const [currentChat, setCurrentChat] = useState(selectedChat || null);
  const [isChatListVisible, setIsChatListVisible] = useState(!selectedChat);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);

  useEffect(() => {
    if (selectedChat) {
      setCurrentChat(selectedChat);
      setIsChatListVisible(false);
    }
  }, [selectedChat]);

  useEffect(() => {
    setFilteredContacts(contacts);
  }, [contacts]);

  const updateContactsOnMessage = (data) => {
    console.log(data, "updateContactsOnMessage");

    setContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact._id === data.sentBy || contact._id === data.to
          ? { ...contact, lastMessage: data }
          : contact,
      ),
    );
  };
  console.log(contacts, "contacts");

  const handleSearch = (newSearchQuery) => {
    if (newSearchQuery === "") {
      setFilteredContacts(contacts);
    } else {
      setFilteredContacts(
        contacts.filter(
          (contact) =>
            contact.firstName
              ?.toLowerCase()
              .includes(newSearchQuery.toLowerCase()) ||
            contact.lastName
              ?.toLowerCase()
              .includes(newSearchQuery.toLowerCase()),
        ),
      );
    }
  };

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
    setIsChatListVisible(false);
    onChatChange(); // Notify InvestorInbox that the chat room is active
  };

  const handleBackToChatList = () => {
    setIsChatListVisible(true);
    setCurrentChat(null);
    onBack(); // Notify InvestorInbox to show the Inbox text
  };

  return (
    <div
      className={classNames(
        "container d-flex flex-column h-[82vh] md:h-full xl:h-[75vh] w-screen md:w-auto md:ml-10 -mt-8 p-3",
        { "-mt-16": currentChat }, // Apply -mt-16 when currentChat is set
      )}
    >
      <div className="card h-full w-full md:min-h-[23rem] md:w-[65rem] xl:min-h-[33rem]">
        <div className="min-w-full h-full bg-white rounded lg:grid lg:grid-cols-3">
          <div
            className={classNames(
              currentChat && !isChatListVisible ? "hidden md:block" : "block",
              "bg-white border-r lg:col-span-1 md:h-full",
            )}
            style={{ borderColor: "rgb(229 231 235)" }}
          >
            <SearchUsers handleSearch={handleSearch} />
            <div className="h-full overflow-y-auto">
              <ChatList
                changeChat={handleChatChange}
                selectedChat={selectedChat}
                contacts={filteredContacts}
                setContacts={setContacts}
              />
            </div>
          </div>
          <div
            className={classNames(
              currentChat ? "block" : "hidden",
              isChatListVisible ? "hidden md:block" : "block",
              "lg:col-span-2 h-full",
            )}
          >
            {currentChat ? (
              <ChatRoom
                user={currentChat}
                onBack={handleBackToChatList}
                selectedChat={selectedChat}
                updateContactsOnMessage={updateContactsOnMessage}
              />
            ) : (
              <Welcome />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
