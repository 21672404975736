import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import app_config from "../../config";
import {
  fetchStarupStageLabel,
  startupIndustriesLookup,
} from "../../Constants";
import { findCountryLabel } from "../commons/countries";

import { isAvatarImageAvailable } from "../../util";
import AlphabetAvatar from "../AlphabetAvatar";
import { FaCheckCircle } from "react-icons/fa";

const StartupDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [startupData, setStartupData] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("none");
  const [loading, setLoading] = useState(false);
  const [showFullBrief, setShowFullBrief] = useState(false);

  const userType = sessionStorage.getItem("investor") ? "investor" : "startup";

  const token = sessionStorage.getItem("token");

  const fetchStartupById = async () => {
    setLoading(true);
    const res = await fetch(`${app_config.apiurl}/users/getbyid/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();
    console.log(data);
    setConnectionStatus(data.connectionRequestStatus);
    setStartupData(data.result);
    setLoading(false);
  };

  useEffect(() => {
    fetchStartupById();
  }, [id]);

  const sendConnectionRequest = async () => {
    console.log("connection send");

    await fetch(`${app_config.apiurl}/connection-requests/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ to: id }),
    });
    fetchStartupById();
  };

  // Function to navigate based on the current path
  const openChat = (startupData) => {
    if (location.pathname.includes("/startup/introai")) {
      navigate("/startup/inbox", { state: { selectedUser: startupData } });
    } else {
      navigate("/investor/inbox", { state: { selectedUser: startupData } });
    }
  };

  const renderButtonSection = () => {
    // Logic for startup user
    let buttonLabel;
    let buttonAction;
    let isDisabled;
    if (userType === "investor") {
      return (
        <button
          className="p-2 rounded-lg"
          style={{
            backgroundColor: "#b0ff4b",
            color: "black",
            width: "fit-content",
          }}
          onClick={() => openChat(startupData)}
        >
          Start Chatting
        </button>
      );
    }
    // Logic for startup user
    buttonLabel = "Connect";
    buttonAction = () => sendConnectionRequest();
    isDisabled = false;

    if (connectionStatus === "approved") {
      buttonLabel = "Start Chatting";
      buttonAction = () => openChat(startupData);
    } else if (connectionStatus === "pending") {
      buttonLabel = "Pending";
      isDisabled = true;
    }
    return (
      <button
        className="p-2 rounded-lg"
        style={{
          backgroundColor: "#b0ff4b",
          color: "black",
          width: "fit-content",
        }}
        onClick={buttonAction}
        disabled={isDisabled}
      >
        {buttonLabel}
      </button>
    );
  };

  const displayDetails = () => {
    if (!loading && startupData) {
      return (
        <>
          <div>
            <div className=" h-[12rem] flex justify-start px-6 md:px-0">
              <h1 className="text-black font-bold text-[2.5rem] pt-4 md:ml-20 mt-4">
                Details
              </h1>
            </div>
            <div className="sm:px-12 mt-[-4rem] max-w-6xl flex flex-col sm:flex-row ">
              <div className="sm:w-[37%] mb-4 sm:mb-0">
                <div className="flex flex-col gap-4 items-center py-6 sm:p-6  bg-[#fff] mx-4 rounded-xl shadow-xl">
                  <div className=" ">
                    {isAvatarImageAvailable(startupData.startupimage) ? (
                      <img
                        className="w-32 h-32 rounded-full"
                        src={startupData.startupimage}
                        alt="avatar"
                      />
                    ) : (
                      <AlphabetAvatar
                        alphabet={startupData.firstName.charAt(0)}
                      />
                    )}
                  </div>
                  <div className=" flex flex-col gap-2 items-center ">
                    <div className="flex gap-x-2 justify-center  items-center ">
                      <span
                        className={`text-2xl  ${startupData.firstName.length > 18 ? "max-w-[70%]" : "max-w-full "}  font-medium text-center capitalize`}
                      >
                        {startupData.firstName}
                      </span>

                      {startupData.verificationStatus === "verified" && (
                        <FaCheckCircle
                          className={`${startupData.firstName.length > 20 ? "-ml-2" : ""} text-green-600`}
                          size={`${startupData.firstName.length > 20 ? "1.3rem" : "1.2rem"}`}
                        />
                      )}
                    </div>
                    {startupData?.brief && (
                      <>
                        <p className="text-center px-4">
                          <i className="fas fa-quote-left"></i>{" "}
                          {startupData.brief.split(" ").length > 25 &&
                          showFullBrief
                            ? startupData.brief
                            : startupData.brief.split(" ").length < 25
                              ? startupData.brief
                              : `${startupData.brief.split(" ").slice(0, 25).join(" ")}...`}
                        </p>
                        {startupData.brief.split(" ").length > 25 && (
                          <button
                            onClick={() => setShowFullBrief(!showFullBrief)}
                            className="text-blue-500 underline mt-2"
                          >
                            {showFullBrief ? "Read Less" : "Read More"}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  <div>{renderButtonSection()}</div>
                </div>
              </div>
              <div className="sm:w-[63%] px-6  bg-[#fff] shadow-xl rounded-xl mb-4  mx-4 h-fit">
                {/* Basic information */}
                <div>
                  <div className="py-2 border-b border-[#d3cdcd]">
                    <h2 className="font-medium text-[19px]">
                      Startup Information
                    </h2>
                  </div>
                  <div className="flex flex-col ">
                    <div className="flex flex-col md:flex-row">
                      <div className="flex-1 bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Startup Type:</p>
                          <p className="font-medium">
                            {startupData.type || "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="flex-1 bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Startup Name:</p>
                          <p className="font-medium">
                            {startupData.startupName || "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row">
                      <div className="flex-1 bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Startup stages:</p>
                          <p className="font-medium">
                            {fetchStarupStageLabel(startupData.startupStages) ||
                              "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="flex-1 bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">
                            Current Raised Amount:
                          </p>
                          <p className="font-medium">
                            {startupData.currentRaiseAmount || "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row">
                      <div className="flex-1 bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Interests:</p>
                          <p className="font-medium">
                            {startupData.interests || "Not Available"}
                          </p>
                        </div>
                      </div>

                      <div className="flex-1 bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Industry:</p>
                          <p className="font-medium">
                            {startupIndustriesLookup[startupData.industry] ||
                              "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="py-2 border-b border-[#d3cdcd]">
                    <h2 className="font-medium text-[19px]">Intro AI</h2>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-col md:flex-row justify-around">
                      <div className="sm:w-[50%] flex flex-col">
                        <div className="bg-[#fff] rounded-xl p-3 ">
                          <div className=" flex flex-col gap-2 ">
                            <p className="text-[#a3a0a0]">Support needed:</p>
                            <p className="font-medium">
                              {startupData.support_needed.join(", ") ||
                                "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm:w-[50%] flex flex-col ">
                        <div className="bg-[#fff] rounded-xl p-3">
                          <div className=" flex flex-col gap-2 ">
                            <p className="text-[#a3a0a0]">Strongest Skills:</p>
                            <p className="font-medium">
                              {startupData.strongest_skills.join(", ") ||
                                "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row justify-around md:mt-2 mt-1">
                      <div className="sm:w-[50%] flex flex-col">
                        <div className="bg-[#fff] rounded-xl p-3  ">
                          <div className=" flex flex-col gap-2 ">
                            <p className="text-[#a3a0a0]">
                              Years of experience:
                            </p>
                            <p className="font-medium">
                              {startupData.years_of_experience ||
                                "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm:w-[50%] flex flex-col">
                        <div className="bg-[#fff] rounded-xl p-3">
                          <div className=" flex flex-col gap-2 ">
                            <p className="text-[#a3a0a0]">Interested In:</p>
                            <p className="font-medium">
                              {startupData.interested_in.join(", ") ||
                                "Not Available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#fff] rounded-xl p-3 w-full md:mt-2 mt-1">
                      <div className=" flex flex-col gap-2 ">
                        <p className="text-[#a3a0a0]">Intro Message</p>
                        <p className="font-medium">
                          {startupData.intro_message || "Not Available"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="py-2 border-b border-[#d3cdcd]">
                    <h2 className="font-medium text-[19px]">
                      Basic Information
                    </h2>
                  </div>
                  <div className="flex flex-col sm:flex-row">
                    <div className="sm:w-[50%]">
                      <div className="bg-[#fff] rounded-xl p-3">
                        <div className="flex flex-col gap-2">
                          <p className="text-[#a3a0a0]">Website:</p>
                          {startupData.website ? (
                            <a
                              href={startupData.website}
                              target="_blank"
                              className="font-medium overflow-hidden whitespace-nowrap"
                              style={{
                                maxWidth: "300px",
                                textOverflow: "ellipsis",
                                display: "block",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {startupData.website}
                            </a>
                          ) : (
                            <p className="text-black font-medium">
                              Not Available
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">LinkedIn URL</p>
                          <a
                            href={startupData.linkedInUrl || "#"}
                            target="_blank"
                            rel="noreferrer"
                            className="font-medium overflow-hidden whitespace-nowrap"
                            style={{
                              maxWidth: "300px",
                              textOverflow: "ellipsis",
                              display: "block",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {startupData.linkedInUrl}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="sm:w-[50%]">
                      <div className="bg-[#fff] rounded-xl p-3 ">
                        <div className=" flex flex-col gap-2 ">
                          <p className="text-[#a3a0a0]">Location:</p>
                          <p className="font-medium">
                            {findCountryLabel(startupData.location) ||
                              "Not Available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="text-center mt-64">
          {/* <h1 style={{ color: "#518f3d" }}>Loading...</h1> */}
          <CircularProgress color="success" />
        </div>
      );
    }
  };

  return displayDetails();
};

export default StartupDetails;
