import { Box, CssBaseline, styled } from "@mui/material";
import React, { useEffect, useState } from "react";

import DashboardHeader from "./DashboardHeader";
import DashboardSideBar from "./DashboardSideBar";

const drawerWidth = 180;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isMobileView",
})(({ theme, open, isMobileView }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isMobileView ? 0 : `-${drawerWidth}px`,
  ...(open &&
    !isMobileView && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DashboardContainer = ({ menuItems, isMobileView, children }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(!isMobileView);
  const currentUser = JSON.parse(sessionStorage.getItem("startup"));

  useEffect(() => {
    console.log("isMobileView at DashboardContainer: ", isMobileView);
    const handleResize = () => {
      setIsSideBarOpen(!isMobileView);
    };
    handleResize();
  }, [isMobileView]);

  const toggleSideBar = () => {
    setIsSideBarOpen((prev) => !prev);
  };

  return (
    <Box>
      <CssBaseline />
      <DashboardHeader
        menuItems={menuItems}
        isMobileView={isMobileView}
        profileImageUrl={currentUser.startupimage}
        userName={currentUser.firstName}
        isSideBarOpen={isSideBarOpen}
        handleSideBarOpen={toggleSideBar}
      />
      <Box sx={{ display: "flex" }}>
        <DashboardSideBar
          menuItems={menuItems}
          isMobileView={isMobileView}
          sideBarOpen={isSideBarOpen}
          onSideMenuClick={toggleSideBar}
        />
        <Main open={isSideBarOpen && !isMobileView} isMobileView={isMobileView}>
          <DrawerHeader className="hidden" />
          {children}
        </Main>
      </Box>
    </Box>
  );
};

export default DashboardContainer;
