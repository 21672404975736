import { Field, Formik } from "formik";
import { MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import * as Yup from "yup";
import app_config from "../../config";
import Header from "./Header";

const Contact = () => {
  const userSubmit = async (formdata, { setSubmitting }) => {
    console.log(formdata);
    setSubmitting(true);
    const res = await fetch(`${app_config.apiurl}/contact/add`, {
      method: "POST",
      body: JSON.stringify(formdata),
      headers: { "Content-Type": "application/json" },
    });
    console.log(res.status);
    setSubmitting(false);

    if (res.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Thank You ",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please try again",
      });
    }
  };

  const ContactSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .max(14, "Name must be atmost 10 characters"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    message: Yup.string()
      .max(100, "Message limit exceeded")
      .required("Message is required"),
  });

  return (
    <>
      <Header />

      <div className="row m-3 py-5">
        <div className="col-md-5 mt-5 mx-auto mb-5">
          <h2 className="font-medium text-4xl py-3 text-black">Contact us</h2>
          <h5 className="text-1xl text-black">
            We are here to help you and answer any questions you may have. Here
            is how to reach us!
          </h5>
        </div>
        <div className="col-md-5 text-center ">
          <img
            src="https://i0.wp.com/pages.milaap.org/wp-content/uploads/2022/01/Contact_illustration.png?w=328&ssl=1"
            alt=""
          />
        </div>
      </div>
      <Formik
        initialValues={{ name: "", email: "", message: "", copy: true }}
        validationSchema={ContactSchema} // Added the validation schema here
        onSubmit={userSubmit}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          isSubmitting,
          errors,
          touched,
        }) => (
          <div className="m-4 md:!m-12">
            <form
              className="card p-8 md:p-8"
              style={{ height: "fit-content" }}
              onSubmit={handleSubmit}
            >
              <div className="md:px-5">
                <p className="h4 py-2">Let us know what you think</p>

                {/* <div className='row mt-5'> */}

                <div className="col-md-4">
                  {/* Name input */}
                  <div className="  form-outline mb-4">
                    <MDBInput
                      label="Name"
                      type="text"
                      maxLength={15}
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                    />
                    {errors.name && touched.name ? (
                      <div>{errors.name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  {/* Email input */}
                  <div className=" col-md-6 form-outline mb-4 ">
                    <MDBInput
                      label="Email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      name="email"
                    />
                    {errors.email && touched.email ? (
                      <div>{errors.email}</div>
                    ) : null}
                  </div>
                </div>

                {/* Message input */}
                <div className="col-md-4 ">
                  <MDBTextArea
                    label="Message"
                    style={{ resize: "none" }}
                    // maxLength={100}
                    placeholder="You can type any suggestions or queries you might have.Let us help you!"
                    value={values.message}
                    onChange={handleChange}
                    name="message"
                    rows="5"
                    cols="33"
                  />
                  {errors.message && touched.message ? (
                    <div>{errors.message}</div>
                  ) : null}
                </div>
                {/* Checkbox */}
                <div className="form-check  mb-4 ">
                  <Field
                    type="checkbox"
                    id="form4Example4"
                    name="copy"
                    className="form-check-input "
                    style={{ backgroundColor: "#b0ff4b", color: "#fffefe" }}
                  />
                  <label className="form-check-label" htmlFor="form4Example4">
                    Send me a copy of this message
                  </label>
                </div>
                {/* Submit button */}

                <button
                  disabled={isSubmitting}
                  className="btn btn-block mb-4 text-black"
                  style={{ backgroundColor: "#b0ff4b", width: "200px" }}
                  //onClick={userSubmit}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default Contact;
